const defaults = () => {
    return {
      layoutType: 'vertical',
      layoutWidth: 'fluid',
      sidebarSize: 'lg',
      topbar: 'light',
      mode: 'light',
      position: 'fixed',
      sidebarView: 'default',
      sidebarColor: 'dark',
      updated: new Date(),
      lang: 'ru',
      loading: false,
      symbols: null,
      show_staff_modal: null,
  }
}

export const state = defaults()

export const mutations = {
  CHANGE_TOGGLE_SHOW_STAFF_MODAL(state) {
    state.show_staff_modal = !state.show_staff_modal;
  },
  CHANGE_SHOW_STAFF_MODAL(state, show_staff_modal) {
    state.show_staff_modal = show_staff_modal;
  },
  CHANGE_LAYOUT(state, layoutType) {
    state.layoutType = layoutType;
  },
  CHANGE_LAYOUT_WIDTH(state, layoutWidth) {
    state.layoutWidth = layoutWidth;
  },
  CHANGE_SIDEBAR_TYPE(state, sidebarSize) {
    state.sidebarSize = sidebarSize;
  },
  CHANGE_TOPBAR(state, topbar) {
    state.topbar = topbar;
  },
  CHANGE_MODE(state, mode) {
    state.mode = mode;
  },
  CHANGE_POSITION(state, position) {
    state.position = position;
  },
  CHANGE_SIDEBAR_VIEW(state, sidebarView) {
    state.sidebarView = sidebarView;
  },
  CHANGE_SIDEBAR_COLOR(state, sidebarColor) {
    state.sidebarColor = sidebarColor;
  },
  SET_UPDATED(state) {
    state.updated = new Date();
  },
  SET_LANG(state, payload) {
    state.lang = payload;
  },
  SET_LOADING(state, payload) {
    state.loading = true;
  },
  LOADED(state, payload) {
    state.loading = false;
  },
  SET_SYMBOLS(state, payload) {
    state.symbols = payload;
  },
  LOAD_DEFAULTS(state) {
    Object.assign(state, {...defaults()});
  },
}

export const actions = {
  setLoading({ commit }) {
    commit('SET_LOADING');
  },
  loaded({ commit }, payload = 'global') {
    commit('LOADED', payload);
  },
  setLang({ commit }, payload) {
    commit('SET_LANG', payload);
  },
  setUpdated({ commit }) {
    commit('SET_UPDATED');
  },
  toggleShowStaffModal({ commit }) {
    commit('CHANGE_TOGGLE_SHOW_STAFF_MODAL');
  },
  changeShowStaffModal({ commit }, v) {
    commit('CHANGE_SHOW_STAFF_MODAL', v);
  },
  changeLayoutType({ commit }, { layoutType }) {
    commit('CHANGE_LAYOUT', layoutType);
    document.body.removeAttribute("style");
  },

  changeLayoutWidth({ commit }, { layoutWidth }) {
    commit('CHANGE_LAYOUT_WIDTH', layoutWidth)
  },

  changeSidebarSize({ commit }, { sidebarSize }) {
    commit('CHANGE_SIDEBAR_TYPE', sidebarSize)
  },

  changeTopbar({ commit }, { topbar }) {
    commit('CHANGE_TOPBAR', topbar)
  },

  changeMode({ commit }, { mode }) {
    commit('CHANGE_MODE', mode)
  },

  changePosition({ commit }, { position }) {
    commit('CHANGE_POSITION', position)
  },

  changeSidebarView({ commit }, { sidebarView }) {
    commit('CHANGE_SIDEBAR_VIEW', sidebarView)
  },

  changeSidebarColor({ commit }, { sidebarColor }) {
    commit('CHANGE_SIDEBAR_COLOR', sidebarColor)
  },
  setSymbols({ commit }, symbols) {
    commit('SET_SYMBOLS', symbols)
  },

  loadDefaults({ commit }) {
    commit('LOAD_DEFAULTS')
  }
}
