import azzh from "@/helpers/helpers";
import store from "@/state/store";
import {inject, reactive} from "vue";


class WebsocketConn {
	constructor() {
		this.conn = false;
		this.emitter = null
		// this.connect() // по умолчанию сразу коннектиться
	}

	getConn() {
		return this.conn
	}

	connect() {
		console.log("connect")
		try {
			if (!azzh.user().access) throw "Please Sign In";

			this.conn = new WebSocket(azzh.getBackendUrl(true) + 'user?token=' + azzh.user().access)

			this.conn.onopen = () => {
				// store.dispatch("alerts/success", "WS connected!")
			}

			this.conn.onmessage = r => {
				let data = JSON.parse(r.data)
				if ('action' in data) {
					// console.log(data)
					let action = data['action']
					switch (action) {
						case 'text-message':
							store.dispatch("alerts/success", data['message'])
							break
						case "update-orders":
							this.emitter.emit("refresh")
							break
						case "symbol-price":
							this.emitter.emit("symbol-price", data)
							break
					}
				} else console.log(data)
			}

			this.conn.onclose = r => {
				store.dispatch("alerts/error", "WS is disconnected!").then(() => {
					this.conn = false
				})
			}

		} catch (error) {
			store.dispatch('alerts/error', error)
		}
	}

	disconnect() {
		console.log("disconnect")
		if(this.conn) this.conn.close()
		this.conn = false
	}

	send(data) {
		if(this.conn) this.conn.send(JSON.stringify(data))
		else store.dispatch("alerts/error", "Cant send! WS is disconnected!")
	}
}

const wsconn = {
	install: (app, options) => {
		const wsconn = new WebsocketConn()
		wsconn.emitter = options['emitter']
		app.config.globalProperties.$wsconn = reactive(wsconn);
		app.provide('wsconn', app.config.globalProperties.$wsconn)
	}
}

export default wsconn