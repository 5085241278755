import store from "@/state/store";
import router from "@/router";
import { computed } from 'vue'
import {ElMessageBox} from "element-plus";

import dayjs from "dayjs";
let utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

let dayOfYear = require('dayjs/plugin/dayOfYear')
dayjs.extend(dayOfYear)

let LocalizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(LocalizedFormat)

const azzh = {
	oh, pick, dec, format_sm, format_dec, confirm, message_box, prettyDtDjs, perc_from, sum,
	prettyDt,
	nFormatter,
	dtToUtcSec,
	generatePeriod,
	strDtToPrettyDt,
	getBackendUrl,
	secondsToPrettyDt,
	parseStrToDt,
	logOut,
	prettyFloat,
	user: () => store.state.auth.user,
	isNotEmpty: (obj) =>  obj && Object.keys(obj).length > 0,
	randmm,
	isLoading: () => store.state.layout?.loading ?? false,
	isLoggedIn, isStaff, isDeveloper, isOwnerOrStaff, isOwner, isUserGE, getUserLevel, isInvestorW1ActiveBot,
	setPeriod,
	avatarUrl : (md5) => `https://www.gravatar.com/avatar/${md5}?d=identicon&s=150`,
}

export default azzh

export const staff_modal = computed({
  get: () => store.state.layout?.show_staff_modal ?? null,
  set: (v) => store.dispatch('layout/changeShowStaffModal', v)
})

function isLoggedIn() { return  !!store.state.auth?.user?.access }
function isStaff() { return  isLoggedIn() && !!store.state.auth.user?.is_staff }
function isDeveloper() { return  isLoggedIn() && store.state.auth?.user?.groups.includes('developers')}
function isOwner(obj, owner_field = 'owner') {
	return isLoggedIn() && obj.hasOwnProperty(owner_field) && store.state.auth.user?.id && store.state.auth.user?.id === obj[owner_field]
}
function isOwnerOrStaff(obj, owner_field = 'owner') {
	return isStaff() || isOwner(obj, owner_field)
}

function getUserLevel() {
	let user_level = 1
	if(store.state.auth.user.groups.includes('owner')) user_level = 99
	else if(store.state.auth.user.groups.includes('developers')) user_level = 98
	else if(store.state.auth.user.groups.includes('trader')) user_level = 10
	else if(store.state.auth.user.groups.includes('investor')) user_level = 5
	return user_level
}

function isUserGE(level = 0) {
	if(level === undefined || !isLoggedIn()) return false
	if(store.state.auth.user.groups.length <= 0) return 1
	return  getUserLevel() >= level
}

function isInvestorW1ActiveBot() {
	return store.state.auth.user.groups.includes('investor') && store.state.auth.user?.bots_active_count === 1
}

function confirm(body_text) {
	return ElMessageBox.alert(body_text, 'Please confirm', {confirmButtonText: 'Yes', cancelButtonText: 'No', showCancelButton: true,})
}

function message_box(title, body_text, btn_text) {
	return ElMessageBox.alert(body_text, title, {confirmButtonText: btn_text, showCancelButton: false,})
}

function oh(o, prop) {
	return Object.prototype.hasOwnProperty.call(o, prop)
}

function pick(o, fields = []) {
	let ret = Object.assign({...o})
	Object.keys(ret).forEach((f) => {
		if(!fields.includes(f)) delete ret[f]
	})

	return ret
}

function dec(val, decimals_ = 2, pretty = false) {

	// return val ? parseFloat(val.toFixed(decimals)) : 0
	if (val === null || typeof val === 'undefined' || isNaN(val)) return 0

	// Отсекаю, а не округляю!
	let parsed = parseFloat(val).toFixed(12)
    let decimals = Math.pow(10, decimals_);
	let r = Math.floor(parsed * decimals) / decimals
	if (pretty) r = prettyFloat(r)
	return r
}

function format_sm(v, side, sm) {
	v = v === undefined  || v === null || isNaN(v) ? 0 : parseFloat(v)
	let k = side + "_dec"
	let dec_ = sm !== undefined && oh(sm, k) ? sm[k] : 2
	return  prettyFloat(v.toFixed(dec_).replace(/\.?0*$/,''))
}

function format_dec(v, dec, drop_zeros = true) {
	v = v === undefined || v === ""  || v === null || isNaN(v) ? 0 : parseFloat(v)
	dec = dec === undefined  || dec === null || isNaN(dec) ? 0 : parseFloat(dec)
	if(dec < 0) dec = 0
	else if(dec > 19) dec = 19
	let res = prettyFloat(v.toFixed(dec))
	res = drop_zeros && dec > 0 ? res.replace(/\.?0*$/,'') : res
	return res
}

function prettyFloat(v) {
	// пробела между тысячами
    const n = String(v), p = n.indexOf('.')
    return n.replace(
        /\d(?=(?:\d{3})+(?:\.|$))/g,
        (m, i) => p < 0 || i < p ? `${m} ` : m
    )
}

function perc_from(v, from, dec = 2) {
	if(typeof v === 'string') v = parseFloat(v)
	if(typeof from === 'string') from = parseFloat(from)
	let pow = Math.pow(10, dec);
	return  Math.round((v*100/from)*pow) / pow
}

function prettyDt(dt) {
	if(typeof dt === 'string') return strDtToPrettyDt(dt)

	if(dt instanceof Date) {
		let date = [
			dt.getFullYear(),
			(dt.getMonth() + 1).toString().padStart(2, '0'),
			dt.getDate().toString().padStart(2, '0')
		]
		let time = [
			dt.getHours().toString().padStart(2, '0'),
			dt.getMinutes().toString().padStart(2, '0'),
			dt.getSeconds().toString().padStart(2, '0'),
		]
		return `${date.join('-')} ${time.join(':')}`
	} else return dt

}

function prettyDtDjs(dt, format="LLL") {
	// https://day.js.org/docs/en/display/format
	return  dayjs(dt).format(format)
}

function parseStrToDt(str_dt) {
	try {
		return new Date(Date.parse(str_dt))
	} catch (e) {
		// console.log(`parseStrToDt err ${str_dt} isnt a date`)
	}
}

function nowUtc() {
	return dayjs().utc()
}

function strDtToPrettyDt(str_dt) {
	return prettyDt(parseStrToDt(str_dt))
}

function  secondsToPrettyDt(_seconds) {

	let _s = parseInt(_seconds)
	let d = Math.floor(_s / 86400)
	let h = Math.floor((_s - (d*86400)) / 3600);
	let m = Math.floor((_s - (h * 3600) - (d*86400)) / 60);
	let s = _s - (h * 3600) - (m * 60) - (d*86400);

	let r = ""
	if(d > 0) r += `${d.toString().padStart(2, '0')} `
	r += `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`
	return r
}

function logOut() {
	store.dispatch('auth/logoutAuth')
	store.dispatch('layout/loadDefaults')
	store.dispatch('lists/filter_all_defaults')
	router.push('/login')
}

function randmm(min, max) {
	return Math.floor(Math.random() * max) + min
}

function getBackendUrl(ws = false) {
	return ws ? (process.env.VUE_APP_HOST_IS_SSL === '1' ? 'wss://' : 'ws://') +  process.env.VUE_APP_HOST + '/ws/' : (process.env.VUE_APP_HOST_IS_SSL === '1' ? 'https://' : 'http://') +  process.env.VUE_APP_HOST + '/api/'
}

function dtToUtcSec(dt, dropTz = true) {
	let t = dt.getTime()
	if(dropTz) t += -60000*dt.getTimezoneOffset()
	return (t/1000).toFixed(0)
}

function generatePeriod(days = 7) {
	let to = dtToUtcSec(new Date(), true)
	let from = to - days*24*60*60
	return `${from}-${to}`
}

function setPeriod(v) {
	/**
	 * Конвертит массив дат в строку с таймштампами  12121212-98459465914 для передачи в бэк
	 */
	if (!v || v.length <= 1) return null
	// даты передавай всегда 00:00 - на бэке привожу  + timedelta(hours=24)
	v = dayjs(v[0]).utc(true).hour(0).minute(0).unix() + '-' + dayjs(v[1]).utc(true).hour(0).minute(0).unix()
	return v
}

function converToUtd(dt) {
	return dayjs(dt).utc(false)
}

function nFormatter(num, digits) {
	/**
	 * Форматирует 1000 = 1k, 1000000 = 1M
	 * @type {[{symbol: string, value: number},{symbol: string, value: number},{symbol: string, value: number},{symbol: string, value: number},{symbol: string, value: number},null,null]}
	 */
	const lookup = [
		{value: 1, symbol: ""},
		{value: 1e3, symbol: "k"},
		{value: 1e6, symbol: "M"},
		{value: 1e9, symbol: "G"},
		{value: 1e12, symbol: "T"},
		{value: 1e15, symbol: "P"},
		{value: 1e18, symbol: "E"}
	];
	const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
	let item = lookup.slice().reverse().find(function (item) {
		return num >= item.value;
	});
	return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}

function sum(arr, field, start = 0) {
	/**
	 * Суммирую значения поля в массиве объектов
	 */
	return arr.reduce((a, b) => a + (b[field] || 0), start)
}