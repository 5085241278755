import {backService, q} from "@/helpers/back";
import router from "@/router";
import { version } from "../../../package.json";
import store from "@/state/store";
import { faker } from '@faker-js/faker';

function userDefault() {
    return {
        username: 'Аноним',
        access : null,
        refresh : null,
        updated_at: new Date(0),
        id: null,
    }
}

export const state = {
    user:  userDefault(),
    assets: 0,
    version: null,
    fingerprint: faker.random.alphaNumeric(18)
}

export const actions = {
    loginAuth(e, payload) {
        q("token/obtain/", 'post')
            .data(payload)
            .scb(r => {
                e.commit('loginAuth', r.data)
                console.log('loginAuth zendesk/auth')
                this.dispatch('auth/getUser')
                    .then( () => store.dispatch('zendesk/auth').then(r => router.push("/")))
            })
            .ecb(r => {
                store.dispatch('alerts/error', 'Permissions Denied')
            }, 401)
            .ecb(r => {
                store.dispatch('alerts/error', 'Login Error')
            })
            .run()
    },
    logoutAuth(e) {
        e.commit('logoutAuth')
    },
    refreshAccess(e) {
        return new Promise((resolve, reject) => {
            backService.query("token/refresh/", {refresh: e.state.user.refresh}, 'post')
                .then((r) => {
                    e.commit('refreshAccess', r.data)
                    resolve(r)
                })
                .catch(r => {
                    state.user.access = null
                    // this.dispatch('alerts/error', `Token refresh failed. Try again or relogin!`)
                    router.push("/login")
                    reject(r)
                })
        })
    },
    getUser(e) {
        return new Promise((resolve, reject) => {
            return backService.query("me")
                .then((r) => {
                    e.commit('setUser', r.data)
                    resolve(r)
                })
                .catch(r => {
                    let statusCode = parseInt(r?.response?.status)
                    if(statusCode === 401) {
                        store.dispatch('auth/logoutAuth').then(() => {
                            return router.push('/login')
                        })
                    } else reject(r)
                })
        })
    }
}

export const mutations = {
        loginAuth(state, payload) {
            state.user.access = payload.access
            state.user.refresh = payload.refresh
            state.user.updated_at = new Date()
            state.user.username = 'Залогинился'
            state.version = version
        },
        logoutAuth(state) {
            Object.assign(state.user, {...userDefault()})
            store.dispatch('zendesk/auth')
        },
        refreshAccess(state, payload) {
            state.user.access = payload.access
            state.user.updated_at = new Date()
        },
        setUser(state, payload) {
            Object.assign(state.user, payload)
            state.user.group = state.user?.groups && state.user?.groups.length > 0 ? state.user?.groups[0] : 'approving'
        },
    }