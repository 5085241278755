import axios from "axios";
import store from "@/state/store";
import router from "@/router";
import azzh from "@/helpers/helpers";

export const backService = {
    query,
};

function query(url, _data = null, method = 'get', download = false) {

	let args = {
		headers: {'content-type': 'application/json'},
		url: url,
		method: method,
	}

	if (download) args['responseType'] = 'blob'

	// Чтобы не запрашивать null abkmnhs
	let data = _data ? {..._data} : {} // чтобы при чистке null не реактивить
	for(let k in data) if(data[k] === null) delete data[k]
	// console.log(data)

	// можно передавать как ендпоинт, так и полную ссылку
	if (!(/^(http(s?)):\/\//i.test(url))) args.url = azzh.getBackendUrl() + url

	args[method === 'get' ? 'params' : 'data'] = data

	if (args.url.includes('token/') || args.url.includes('user/create')) return axios(args)
	if (!store.state.auth.user.access) router.push('/login')

	args.headers['Authorization'] = 'Bearer ' + store.state.auth.user.access

	// ** старый вариант с обновлением токена при КАЖДОМ запросе
	// плохой вариант, тк при заходе с др устройства - токен протухает
	// при получении токена или обновлении - не обн рефреш, а сразу отправляю запрос в бек
	// let token_refreshed = false
	// буду обновлять рефреш при каждом запросе ?..
	// return store.dispatch('auth/refreshAccess')
	// 	.then(() => {
	// 		// set new access token after refreshing
	// 		args.headers['Authorization'] = 'Bearer ' + store.state.auth.user.access
	// 		token_refreshed = true
	// 		return axios(args)
	// 	})

	return axios(args)
}

// **** Q class shortcut ****

export function q(endpoint, method = 'post') {
	return new Q(endpoint, method)
}

class Q {
	/**
	 * Клосс для формирования цепочки запроса
	 * с наполнениями в виде лоадингов, данных, колбэков и проч.
	 *
	 * @type {null}
	 */
	#endpoint = null
	#success_message = false
	#error_message = null
	#loading = null
	#emit_refresh = false
	#method = 'post'
	#data = {}

	#success_callbacks = {}
	#errors_callbacks = {}

	constructor(endpoint, method = 'post') {
		this.#endpoint = endpoint
		this.#method = method
	}

	data(val) {
		this.#data = val
		return this
	}

	smsg(text) {
		this.#success_message = text
		return this
	}

	scb(cb, code = 200) {
		// На каждый успешный статус (200<=x<=299) можно повесить свой обработчик
		if(!Array.isArray(code)) code = [code]
		code.forEach((v) => {
			if(!Object.prototype.hasOwnProperty.call(this.#success_callbacks, v)) this.#success_callbacks[v] = []
			this.#success_callbacks[v].push(cb)
		})

		return this
	}

	ecb(cb, code = 400) {
		// На каждый НЕуспешный статус (400<=x<=499) можно повесить свой обработчик
		if(!Array.isArray(code)) code = [code]
		code.forEach((v) => {
			if(!Object.prototype.hasOwnProperty.call(this.#errors_callbacks, v)) this.#errors_callbacks[v] = []
			this.#errors_callbacks[v].push(cb)
		})

		return this
	}

	emsg(text) {
		this.#error_message = text
		return this
	}

	erfr(val) {
		this.#emit_refresh = !!val
		return this
	}

	loading(val) {
		this.#loading = val
		return this
	}

	run(set_updated = true, set_loading = true, download = false) {
		if (this.#loading) this.#loading.value = true
		if(set_loading) store.dispatch('layout/setLoading')

		query(this.#endpoint, this.#data, this.#method, download)
			.then(r => {
				if (azzh.oh(r, 'data')) {
					if(this.#success_message) store.dispatch('alerts/success', this.#success_message)
					else if(r.data?.message) store.dispatch('alerts/success', r.data.message)

					if(azzh.oh(this.#success_callbacks, r.status) && this.#success_callbacks[r.status].length > 0) {
						this.#success_callbacks[r.status].forEach((cb) => cb(r))
					}

					// if(this.#emit_refresh) emitter.emit('refresh')
					if(set_updated) store.dispatch('layout/setUpdated') // для отображения времени обн в футере
					return r.data
				} else store.dispatch('alerts/error', 'Some went wrong!')
			})
			.catch((r) => {
				let statusCode = parseInt(r?.response?.status)
				let statusText = r?.response?.statusText
				if(azzh.oh(this.#errors_callbacks, statusCode)) this.#errors_callbacks[statusCode].forEach((cb) => cb(r.response.data))
				else if(statusCode === 401) {
					// if(store.state.auth?.user?.access) console.log("token expired")

					store.dispatch('auth/logoutAuth').then(() => {
						return router.push('/login')
					})
				}
				else if(statusCode === 404) {
					// store.dispatch('alerts/error', '404 Not Found')
					// return router.back()
					return {http404: 404}
				}
				else if(statusCode === 403) {
					store.dispatch('alerts/error', '403 Forbidden').then(() => {
						// return {http403: 403}
						return router.push('/page403')
					})
				}
				else {
					let msg = `Error Unknown`
					// console.log(`Error Unknown ${statusCode} ${this.#endpoint}`)
					// console.log(r?.response)

					if (statusCode >= 500 && statusText) msg = statusText
					else if(r?.response?.data) {
						if (Array.isArray(r?.response?.data)) msg = r.response.data[0]
						else if(azzh.oh(r?.response?.data, 'message')) msg = r.response.data.message
					}
					else console.log(r)

					store.dispatch('alerts/error', `${statusCode} | ${msg}`)
				}
			})
			.finally(r => {
				if (this.#loading) this.#loading.value = false
				store.dispatch('layout/loaded')
				// store.dispatch('layout/setUpdated')
			})

	}

}