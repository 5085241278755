import { createWebHistory, createRouter } from "vue-router";
import routes from './routes'
import routes_smarttrade from "./routes_smarttrade";
import appConfig from "../../app.config";
import store from "@/state/store";
import azzh from "@/helpers/helpers";

const router = createRouter({
  history: createWebHistory(),
  routes : [...routes, ...routes_smarttrade],
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: 'history',
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0, left: 0 }
    }
  },
})

// Before each route evaluates...
router.beforeEach(async (routeTo, routeFrom, next) => {

  const authRequired = routeTo.matched.some((route) => route?.meta?.authRequired)

  // todo: Дергать api/me для надежной проверки, но зачем е можно подменить, лучше защищать на бэке ???

  if(authRequired) {
    await store.dispatch('auth/getUser') // обновление статуса юзера ~9ms
    if (routeTo.name !== 'login' && !azzh.isLoggedIn()) return next('/login')
    if (!store.state.auth?.user.groups.length && routeTo.name !== 'approving') return next('/approving')

    const staffOnly = routeTo.matched.some((route) => route?.meta?.staffOnly)
    if (staffOnly && !azzh.isStaff()) return next('/page403')

    if(!azzh.isUserGE(routeTo?.meta?.userLevelGe) && routeTo.name !== 'page403') {
      return next('/page403')
    }

    if(!store.state.auth?.user?.keys_count && azzh.getUserLevel() >= 5 && !azzh.isStaff() && routeTo.name !== 'keys-lost') {
      // Проверка что у Партнера нет ключей
      return next('/keys/lost')
    }

    if(store.state.auth?.user?.keys_count && !store.state.auth?.user?.bots_count && azzh.getUserLevel() === 5 && !azzh.isStaff() && routeTo.name !== 'bots-lost') {
      // Инвестор завел Ключи, но ботов ещё для него не создано
      return next('/bots/lost')
    }

    if(routeTo.name === 'bots-lost' && (store.state.auth?.user?.bots_count || azzh.getUserLevel() !== 5)) return next("/bots")
  }
  else if (routeTo.name === 'login' && azzh.isLoggedIn()) return next('/bots')

  next();
})

router.beforeResolve(async (routeTo, routeFrom, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              // Complete the redirect.
              next(...args)
              reject(new Error('Redirected'))
            } else {
              resolve()
            }
          })
        } else {
          // Otherwise, continue resolving the route.
          resolve()
        }
      })
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return
  }
  document.title = routeTo.meta.title + ' | ' + appConfig.version;
  // If we reach this point, continue resolving the route.
  next()
})

export default router
