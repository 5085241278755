import azzh from "@/helpers/helpers";

const filters_defaults = {
	bots: {
		running: 1,
		is_using: null,
		ordering: '-id',
		stats: 1,
		search: null,
		km: null,
		owner: null,
		sm: null,
		skip_demo: null,
		period: null,
		trade_type: null,
		broker: null,
	},
	nets: {
		ordering: '-created_at',
		is_open: 1,
		search: null,
		owner: null,
		nets: null,
		bot: null,
		sm: null,
	},
	orders: {
		ordering: '-id',
		km: null,
		period: null,
		status: null,
		order_net_type: null,
		search: null,
		sm: null,
		skip_demo: null,
		trade_type: null,
		broker: null,
	},
	keys: {
		ordering: '-created_at',
		search: null,
		is_using: 1,
		broker: null,
		owner: null,
	},
	symbols: {
		ordering: '-abs_price_ch',
		is_bots: null,
		show_bots: true,
		show_stats: true,
		broker: null,
		quote_token: null,
	},
	invoices: {
		ordering: '-id',
		status: null,
		owner: null,
		period: null,
		created_by: null,
	},
	feewallets: {
		ordering: '-id',
		status: null,
	},
	users: {
		ordering: '-id',
		stats: 1,
		search: null,
		role: null,
		status: null,
		bots_active: null,
	},
	profits:{
		period: azzh.generatePeriod(),
		bot: null,
		owner: null,
		quote: null,
		symbol: null,
		sm: null,
		km: null,
		trade_type: null,
		filter_value_by: null,
		filter_value: null,
		broker: null,
		ordering: '-sum_profit_perc',
	}
}

const loadDefaults = () => {
	return {
		bots: {...filters_defaults.bots},
		nets: {...filters_defaults.nets},
		orders: {...filters_defaults.orders},
		keys: {...filters_defaults.keys},
		symbols: {...filters_defaults.symbols},
		profits: {...filters_defaults.profits},
		users: {...filters_defaults.users},
		invoices: {...filters_defaults.invoices},
		feewallets: {...filters_defaults.feewallets},
	}
}

export const state = {
	updated: null,
	filters : loadDefaults(),
	reset: null,
}



export const mutations = {
	FILTER_SET(state, o) {
		// я не хочу чтобы таблица обновлялась при вводе каждого символа
		// а у el-input нельзя так сделать поэтому обновляю содержимое таблиц только по update
		// для моментальных полей используй
		// store.dispatch('lists/filter_set', ['nets', {search: v, update: true}])
		// добавь фн const update_ = () => store.dispatch('lists/filter_set', ['nets', {update: true}])
		if ('update' in o[1]) {
			state.updated = new Date()
			delete o[1].update
		}
		// Чтобы не терять реактивность
		// store.dispatch('lists/filter_set', ['nets', {search: v}])
		Object.assign(state.filters[o[0]], {...state.filters[o[0]], ...o[1] })
	},
	FILTER_CLEAR(state, o) {
		Object.keys(state.filters[o[0]]).forEach(function(key){
			if(!['ordering', 'stats'].includes(key)) {
				state.filters[o[0]][key] = null
			}
		});
		if(o[1]) state.updated = new Date()
		state.reset = new Date()
	},
	FILTER_DEFAULTS(state, o) {
		Object.assign(state.filters[o[0]], filters_defaults[o[0]])
		if(o[1]) state.updated = new Date()
		state.reset = new Date()
	},
	FILTER_ALL_DEFAULTS(state) {
		Object.assign(state.filters, {...loadDefaults()})
		state.updated = null
	}
}

export const actions = {
	filter_set({commit}, o) {
		commit('FILTER_SET', o);
	},
	filter_clear({commit}, o) {
		commit('FILTER_CLEAR', o);
	},
	filter_defaults({commit}, o) {
		commit('FILTER_DEFAULTS', o);
	},
	filter_all_defaults({commit}) {
		commit('FILTER_ALL_DEFAULTS');
	},
}