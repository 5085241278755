// import store from "@/state/store";

export default [
    {path: "/brocheck", name: "brocheck", meta: {title: "brocheck", authRequired: false}, component: () => import("../views/smarttrade/brocheck"),},
    {
        path: "/approving",
        name: "approving",
        meta: {title: "On Approving", authRequired: true},
        component: () => import("../views/account/approving"),
    },
    {
        path: "/profile/:id",
        name: "profile_user_v2",
        meta: {title: "Partner profile", authRequired: true, ownerRequired: true},
        props: true,
        component: () => import("../views/smarttrade/details/profile_v2_overview"),
    },
    {
        path: "/profile/:id/:quote_filter",
        name: "profile_user_v2_quote_filter",
        meta: {title: "Partner profile", authRequired: true, ownerRequired: true},
        props: true,
        component: () => import("../views/smarttrade/details/profile_v2_overview"),
    },
    {
        path: "/user-invoices/:id",
        name: "user-invoices",
        meta: {title: "Partner Invoices", authRequired: true, ownerRequired: true},
        props: route => ({
            id: route.params.id,
            active_tab: 'invoices',
        }),
        component: () => import("../views/smarttrade/details/profile_v2_invoices"),
    },
    {
        path: "/profile-edit/:id",
        name: "profile_edit",
        meta: {title: "Edit Partner profile", authRequired: true, ownerRequired: true},
        props: true,
        component: () => import("../views/profile/profile_edit"),
    },
    {
        path: "/restricted/bot/:id",
        name: "bot-staff",
        props: true,
        meta: {title: "Bot", authRequired: true, staffOnly: true},
        component: () => import("../views/smarttrade/details/bot_staff"),
    },
    {
        path: "/bot/:id",
        name: "bot-v2",
        props: true,
        meta: {title: "Bot", authRequired: true},
        component: () => import("../views/smarttrade/details/bot_v2"),
    },
    {
        path: "/details/net/:id",
        name: "details-net",
        props: true,
        meta: {title: "Net", authRequired: true},
        component: () => import("../views/smarttrade/details/net"),
    },
    {
        path: "/keys/:id",
        name: "details-keys",
        props: true,
        meta: {title: "Keys", authRequired: true},
        component: () => import("../views/smarttrade/details/keys"),
    },
    {
        path: "/keys/create",
        name: "keys-create",
        // props: {creating: true},
        meta: {title: "Add New Keys", authRequired: true},
        component: () => import("../views/smarttrade/details/keys"),
    },
    {
        path: "/keys/lost",
        name: "keys-lost",
        // props: {creating: true},
        meta: {title: "Keys Lost", authRequired: true},
        component: () => import("../views/account/keys_lost"),
    },
    {
        path: "/bots/lost",
        name: "bots-lost",
        // props: {creating: true},
        meta: {title: "Bots Lost", authRequired: true},
        component: () => import("../views/account/bots_lost"),
    },
    {
        path: "/bot/edit/:id",
        name: "bot-edit",
        meta: {title: "Bot edit", authRequired: true, userLevelGe: 10},
        props: true,
        component: () => import("../views/smarttrade/forms/BotEdit"),
    },
    {
        path: "/bot/create",
        name: "bot-create",
        props: true,
        meta: {title: "Bot create", authRequired: true, userLevelGe: 10},
        component: () => import("../views/smarttrade/forms/BotEdit"),
    },
    {
        path: "/partners",
        name: "partners",
        meta: {title: "Partners", authRequired: true, staffOnly: true},
        component: () => import("../views/smarttrade/lists/partners"),
    },
    {
        path: "/paper-trading",
        name: "paper-trading",
        meta: {title: "Paper Trading", authRequired: true, staffOnly: true},
        component: () => import("../views/admin/papertrading"),
    },
    {
        path: "/bots",
        name: "bots",
        meta: {title: "Bots", authRequired: true},
        props: true,
        component: () => import("../views/smarttrade/lists/bots"),
    },
    {
        path: "/invoices",
        name: "invoices",
        meta: {title: "Invoices", authRequired: true},
        component: () => import("../views/smarttrade/lists/invoices"),
    },
    {
        path: "/invoice/:id",
        name: "invoice",
        props: true,
        meta: {title: "Invoice", authRequired: true},
        component: () => import("../views/smarttrade/details/invoice"),
    },
    {
        path: "/keys",
        name: "keys",
        meta: {title: "Keys", authRequired: true},
        component: () => import("../views/smarttrade/lists/keys"),
    },
    {
        path: "/symbols",
        name: "symbols",
        meta: {title: "Symbols", authRequired: true, staffOnly: true},
        component: () => import("../views/smarttrade/lists/symbols"),
    },
    {
        path: "/monitor/orders",
        name: "monitor-orders",
        meta: {title: "Orders", authRequired: true, userLevelGe: 10},
        component: () => import("../views/smarttrade/lists/orders"),
    },
    {
        path: "/monitor/nets",
        name: "monitor-nets",
        meta: {title: "Nets", authRequired: true, staffOnly: true},
        component: () => import("../views/smarttrade/lists/nets"),
    },
    {
        path: "/monitor/streams",
        name: "monitor-streams",
        meta: {title: "Streams", authRequired: true},
        component: () => import("../views/smarttrade/lists/streams"),
    },
    {
        path: "/profits/daily",
        name: "profits-daily",
        meta: {title: "Profits Daily", authRequired: true},
        component: () => import("../views/smarttrade/lists/profits"),
        props: { grouping: 'daily' }
    },
    {
        path: "/profits/broker",
        meta: {title: "Stats by Exchange", authRequired: true},
        component: () => import("../views/smarttrade/lists/profits"),
        props: { grouping: 'broker' }
    },
    {
        path: "/profits/partners",
        name: "stats-partners",
        meta: {title: "Stats by partners", authRequired: true, staffOnly: true},
        component: () => import("../views/smarttrade/lists/profits"),
        props: { grouping: 'owners' }
    },
    {
        path: "/profits/quote",
        name: "stats-quote",
        meta: {title: "Stats by quote", authRequired: true},
        component: () => import("../views/smarttrade/lists/profits"),
        props: { grouping: 'quote' }
    },
    {
        path: "/profits/bots",
        name: "stats-bots",
        meta: {title: "Stats by Bots", authRequired: true},
        component: () => import("../views/smarttrade/lists/profits"),
        props: { grouping: 'bots' }
    },
    {
        path: "/profits/symbols",
        name: "stats-symbols",
        meta: {title: "Stats by Symbols", authRequired: true,  staffOnly: true},
        component: () => import("../views/smarttrade/lists/profits"),
        props: { grouping: 'symbols' }
    },
    {
        path: "/admin/settings",
        name: "admin-settings",
        meta: {title: "Site settings", authRequired: true, staffOnly: true},
        component: () => import("../views/admin/settings"),
    },

    {
        path: "/feewallet/:id",
        name: "feewallet-edit",
        meta: {title: "Feewallet edit", authRequired: true, staffOnly: true},
        props: true,
        component: () => import("../views/smarttrade/forms/FeeWalletEdit"),
    },
    {
        path: "/feewallet/create",
        name: "feewallet-create",
        props: true,
        meta: {title: "Feewallet create", authRequired: true, staffOnly: true},
        component: () => import("../views/smarttrade/forms/FeeWalletEdit"),
    },
    {
        path: "/feewallets",
        name: "feewallets",
        meta: {title: "FeeWallets", authRequired: true, staffOnly: true},
        component: () => import("../views/smarttrade/lists/feewallets"),
    },
]