import {ElMessage, ElNotification} from "element-plus";

export const state = {
    type: null,
    message: null
};

export const mutations = {
    success(state, message) {
        ElNotification({
            title: 'Success',
            message: message,
            type: 'success',
            position: "bottom-right"
        })

        state.type = 'alert-success';
        state.message = message;
    },
    error(state, message) {
        ElNotification({
            title: 'Error',
            message: message,
            type: 'error',
            position: "bottom-right"
        })

        state.type = 'alert-danger';
        state.message = message;
    },
    clear(state) {
        state.type = null;
        state.message = null;
    }
};

export const actions = {
    success({ commit }, message) {
        commit('success', message);
    },
    error({ commit }, message) {
        commit('error', message);
    },
    clear({ commit }) {
        commit('clear');
    }
};
