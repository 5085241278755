import { createApp, ref } from 'vue'
import ElementPlus from 'element-plus'
import dayjs from 'dayjs'
import 'dayjs/locale/en'
import VueApexCharts from "vue3-apexcharts";
// import 'dayjs/locale/ru'
// import ru from 'element-plus/dist/locale/ru.mjs'
import en from 'element-plus/dist/locale/en.mjs'

import appConfig from "../app.config";

import router from "./router";
import i18n from './i18n'
import store from "./state/store";
import mitt from 'mitt';

import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import BootstrapVue3 from 'bootstrap-vue-3';
import vClickOutside from "click-outside-vue3"
import Maska from 'maska';

import '@/assets/scss/config/default/app.scss';
import '@vueform/slider/themes/default.css';
import "element-plus/dist/index.css";
import 'aos/dist/aos.css'
import wsconn from "./wsconn"
import { version } from "../package.json";

import App from './App.vue'
import azzh from "@/helpers/helpers";

const emitter = mitt();

// Понедельник - первый день, пока для одной локали
dayjs.Ls.en ??= {}
dayjs.Ls.en.weekStart = 1
// dayjs.locale('ru')

const app = createApp(App)
app.use(store)
app.use(router)
app.use(BootstrapVue3)
// app.component(VueFeather.type, VueFeather)
// app.use(Particles)
app.use(VueApexCharts)
app.use(Maska)
app.use(i18n)
app.use(vClickOutside)
app.use(ElementPlus, {locale: en})
app.use(wsconn, {'emitter': emitter})

app.provide('emitter', emitter)

appConfig['version'] = version
app.provide('app_config', appConfig)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

// сброс юзера, е версия обновилась
if(store.state?.auth?.version && version !== store.state.auth.version) {
  store.dispatch('alerts/success', `New version ${version} is coming!`)
  azzh.logOut()
}

// предзагрузка виджета зендекса и авторизация
store.dispatch('zendesk/auth')

app.mount('#app')