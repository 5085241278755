import { createI18n } from 'vue-i18n';
import store from '@/state/store'

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages() {
  const locales = require.context('./lang', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const setDateTimeFormats = {
  short: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    timeZone: 'UTC'
  },
  short_numeric: {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
    timeZone: 'UTC'
  },
  long: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    // weekday: 'long',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: 'UTC'
  },
};

const fallback_locale = () => {
  try{
    return (navigator.language || navigator.userLanguage).split('-')[0]
  }catch (e) {
    return 'en'
  }
}

const i18n = createI18n({
  locale: store.state.layout.lang ?? fallback_locale(),
  fallbackLocale: 'ru',
  messages: loadLocaleMessages(),
  datetimeFormats: {
    en: setDateTimeFormats,
    es: setDateTimeFormats,
    de: setDateTimeFormats,
    ru: setDateTimeFormats,
    vn: {
      long: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      },
    },
    'en-GB': setDateTimeFormats,
  },
});

export default i18n