import { createStore } from 'vuex'
import VuexPersist from 'vuex-persist';
// https://github.com/softvar/secure-ls
import SecureLS from "secure-ls";
let ls = new SecureLS({ isCompression: false });

import modules from './modules'

const vuexLocalStorage = new VuexPersist({
  key: 'ststorage',
  // storage: window.localStorage, // or window.sessionStorage or localForage
  storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
  },
})

const store = createStore({
  modules,
  strict: process.env.NODE_ENV !== 'production',
  plugins: [vuexLocalStorage.plugin]
})

export default store