import azzh from "@/helpers/helpers";
import {encode} from "jwt-simple";
import store from "@/state/store";

const script_id = "ze-snippet"

/**
 * https://developer.zendesk.com/api-reference/widget-messaging/web/core/
 */

export const state = {
    is_loading: false,
    is_visible: false,
    unread_count: 0,
    external_id: '',
};


function loadScripts(tryies = 3) {
    // защита от клика во время загрузки
    // console.log('zendesk loadScripts ', tryies)
    return new Promise((resolve, reject) => {
        if(!document.getElementById(script_id)) {
            // console.log('zendesk loading')
            store.dispatch('zendesk/set_loading')
            store.dispatch('zendesk/set_external_id', null)
            const script = document.createElement("script");
            script.type = "text/javascript";
            script.async = true;
            script.id = script_id;
            script.src = "https://static.zdassets.com/ekr/snippet.js?key=" + process.env.VUE_APP_WIDGET_ID;
            // delete window.zE;
            const first = document.getElementsByTagName("script")[0];
            first.parentNode.insertBefore(script, first);

            script.onload = ev => {
                // console.log("zendesk loaded ...")
                store.dispatch('zendesk/set_loaded').then(() => {
                    try {
                        zE("messenger:on", "open", function () {
                            store.dispatch('zendesk/set_loaded')
                                .then(() => store.dispatch('zendesk/set_unread', 0))
                                .then(() => store.dispatch('zendesk/set_visibility', true))
                        })

                        zE("messenger:on", "close", function () {
                            store.dispatch('zendesk/set_loaded')
                                .then(() => store.dispatch('zendesk/set_visibility', false))
                        })

                        zE("messenger:on", "unreadMessages", function (count) {
                            store.dispatch('zendesk/set_unread', count)
                        })

                        store.dispatch('zendesk/set_visibility', false).then(() => zE("messenger", "close"))
                        resolve()

                    } catch (e) {
                        console.log("zendesk onload error ", e)
                        reject()
                    }
                })
            }
        }
        // уже загружен
        else if (store.state?.zendesk?.is_loading || typeof zE === "undefined") {
            if(tryies > 0) return setTimeout(() => loadScripts(--tryies), 100)
            console.log('zendesk failed to load!')
            reject()
        } else {
            resolve()
        }



    })
}

export const mutations = {
    auth(state) {
        if(azzh.isUserGE(10)) {
            console.log('zendesk skipped, because ur staff..')
            return
        }

        loadScripts()
            .then(() => {
                zE("messenger", "loginUser", (callback) => {
                    let payload
                    if (azzh.user()?.id) {
                        payload = {
                            scope: 'user',
                            name: azzh.user().username,
                            email: azzh.user()?.email,
                            external_id: "user_" + azzh.user().id
                        }
                    } else {
                        // генерация анонимного юзера, чтобы избежать запроса имени/почты от зендеска
                        let fp = store.state.auth.fingerprint
                        payload = {
                            scope: 'user',
                            name: 'Аноним_' + fp,
                            email: fp + ".anon@smart-trade.ltd",
                            external_id: "anon_" + fp
                        }
                    }

                    // if(payload.external_id === store.state.zendesk.external_id) {
                    //     console.log("zd auth not needed")
                    //     return
                    // }

                    let token = encode(
                        payload,
                        process.env.VUE_APP_ZENDESK_KEY,
                        'HS256',
                        {header: {'kid': process.env.VUE_APP_ZENDESK_KID}}
                    )

                    callback(token)
                    store.dispatch('zendesk/set_external_id', payload.external_id)
            });
        })
        .catch( r => {
            console.log("zendesk auth failed")
        })
    },
    set_loading(state) {
        state.is_loading = true
    },
    set_loaded(state) {
        state.is_loading = false
    },
    set_visibility(state, visibility) {
        state.is_visible = visibility
    },
    set_unread(state, count) {
        state.unread_count = parseInt(count)
    },
    set_external_id(state, external_id) {
        state.external_id = external_id
    },
    toggle(state) {
        loadScripts().then(() => {
            store.dispatch('zendesk/set_loaded').then(() => {
                zE('messenger', state.is_visible ? 'close' : 'open')
            })
        }).catch( () => {
            store.dispatch('zendesk/set_loaded')
        })
    }
}

export const actions = {
    toggle({ commit }, message) {
        commit('toggle', message);
    },
    set_loading({commit}) {
        commit('set_loading')
    },
    set_loaded({commit}) {
        commit('set_loaded')
    },
    set_unread({commit}, count) {
        commit('set_unread', count)
    },
    set_external_id({commit}, external_id) {
        commit('set_external_id', external_id)
    },
    set_visibility({commit}, visibility) {
        commit('set_visibility', visibility)
    },
    auth({commit}) {
        commit('auth')
    }
}